import React from "react";
import {graphql} from "gatsby";
import Img from "gatsby-image";

export const ImageParagraph = ({node}) => {
  let {alt, width, height} = node.image
  let [maxWidth, maxHeight] = [576, 576]
  let calcHeight = height * maxWidth / width

  if (calcHeight > maxHeight) {
    maxWidth = maxHeight * maxWidth / calcHeight
  }

  return (
      <div className="paragraph paragraph-image">
        <figure className="image mx-auto" style={{maxWidth: `${maxWidth}px`}}>
          <Img
            fluid={node.relationships.image.localFile.childImageSharp.fluid}
            alt={alt}
          />
        </figure>
      </div>
  )
}

export const fragment = graphql`
  fragment ImageParagraph on paragraph__image {
    id
    image: field_image {
      alt
      width
      height
    }
    relationships {
      image: field_image {
        localFile {
          childImageSharp {
            fluid(maxWidth: 576, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`;
