import React from "react";
import { graphql } from "gatsby";
import HtmlParser from "../../components/HtmlParser";

export const TextParagraph = ({ node, isTsExcluded = false }) => {
  const props = isTsExcluded ? {} : {"data-typesense-field": "paragraph_text"};

  return (
    <div className="paragraph paragraph-text" {...props}>
      <div className="content">
        <HtmlParser html={node.text.value}/>
      </div>
    </div>
  )
}

export const fragment = graphql`
  fragment TextParagraph on paragraph__text {
    id
    text: field_text {
      value
    }
  }
`;
