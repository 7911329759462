import React from "react";
import { graphql } from "gatsby";
import { getIconFromExtension } from "../../Utils/functions";

export const FileParagraph = ({ node }) => (
    <div className="paragraph paragraph-file h-full">
        <a
            href={node.relationships.file.localFile.publicURL}
            title={`${node.title} (${node.relationships.file.localFile.prettySize})`}
            className="flex items-center h-full file p-4 bg-primary-light text-white hover:bg-primary transiton-all"
            download
        >
            <i className={`fas fa-${getIconFromExtension(node.relationships.file.localFile.extension)} text-2xl my-2 pr-4`}></i>
            <span className="icon-explain">{node.title}</span>
        </a>
    </div>
)

export const fragment = graphql`
    fragment FileParagraph on paragraph__file {
        id
        title: field_title
        file: field_file {
            description
        }
        relationships {
            file: field_file {
                localFile {
                    publicURL
                    prettySize
                    extension
                }
            }
        }
    }
`;