import React from "react";
import {graphql} from "gatsby";
export const HighlightedParagraph = ({node, isTsExcluded = false}) => {
  const props = isTsExcluded ? {} : {"data-typesense-field": "paragraph_highlighted"};

  return (
    <div className="paragraph paragraph-highlighted bg-primary py-8">
        <div className="skew-primary py-8">
            <div className="relative px-4 text-white" {...props}>
                <div className="content" dangerouslySetInnerHTML={{__html: node.text.value}}/>
            </div>
        </div>
    </div>
  )
}

export const fragment = graphql`
  fragment HighlightedParagraph on paragraph__highlighted {
    id
    text: field_text {
      value
    }
  }
`;
