import React, {useRef} from 'react';
import Webform from "gatsby-drupal-webform";

import WebformFieldEmail from "./Webform/WebformFieldEmail";
import WebformFieldText from "./Webform/WebformFieldText";
import WebformFieldTextarea from "./Webform/WebformFieldTextarea";

const Form = ({ webform }) => {
    const webformId = 'webform-id-' + Math.floor(Math.random() * Date.now())

    const onSuccess = (response) => {
        if (response.settings) {
            window.flash(
                response.settings.confirmation_message,
                'success'
            )

            document.getElementById(webformId).reset();
        }
    }

    const onValidate = (event) => {
        event.preventDefault()

        let isValid = event.currentTarget.checkValidity()

        if (!isValid) {
            window.flash(
                "Au moins un champ de ce formulaire n'est pas conforme. Merci de contrôler votre saisie.",
                'danger'
            )
        }

        return isValid
    }

    return (
        <Webform
            id={webformId}
            webform={webform}
            customComponents={{
                email: WebformFieldEmail,
                textfield: WebformFieldText,
                textarea: WebformFieldTextarea,
            }}
            endpoint={`${process.env.API_URL}${process.env.WEBFORM_ENDPOINT}`}
            onSuccess={onSuccess}
            onValidate={onValidate}
        />
    )
}

export default Form