import React from "react";
import {BannerParagraph} from "../components/Paragraphs/Banner";
import {CardParagraph} from "../components/Paragraphs/Card";
import {HighlightedParagraph} from "../components/Paragraphs/Highlighted";
import {ImageParagraph} from "../components/Paragraphs/Image";
import {ListParagraph} from "../components/Paragraphs/List";
import {TextParagraph} from "../components/Paragraphs/Text";
import {FilesParagraph} from "../components/Paragraphs/Files";
import {FileParagraph} from "../components/Paragraphs/File";
import {AccordionParagraph} from "../components/Paragraphs/Accordion"
import {CollapseParagraph} from "../components/Paragraphs/Collapse"
import {FilesMultiuploadParagraph} from "../components/Paragraphs/FilesMultiupload";
import {ImageGalleryParagraph} from "../components/Paragraphs/ImageGallery";

const components = {
  paragraph__text: TextParagraph,
  paragraph__highlighted: HighlightedParagraph,
  paragraph__banner: BannerParagraph,
  paragraph__image: ImageParagraph,
  paragraph__card: CardParagraph,
  paragraph__list: ListParagraph,
  paragraph__files: FilesParagraph,
  paragraph__files_multiupload: FilesMultiuploadParagraph,
  paragraph__file: FileParagraph,
  paragraph__accordion: AccordionParagraph,
  paragraph__collapse: CollapseParagraph,
  paragraph__image_gallery: ImageGalleryParagraph
};

export const getParagraph = (node, isTsExcluded = false) => {
  if (components.hasOwnProperty(node.type)) {
    const ParagraphComponent = components[node.type];
    return <ParagraphComponent key={node.id} node={node} isTsExcluded={isTsExcluded} />;
  }

  return <p key={node.id}>Unknown type {node.__typename}</p>;
};
