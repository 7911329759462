import React, {useEffect, useState, forwardRef} from "react";
import { graphql } from "gatsby";
import PropTypes from "prop-types";

import HtmlParser from "../../components/HtmlParser";

export const CollapseParagraph = forwardRef(({ node, index, isOpenned, clickHandler }, ref) => {
    const [collapsed, setCollapsed] = useState(!isOpenned);

    const toggle = clickHandler ? clickHandler : () => {
        setCollapsed(!collapsed)
    }

    useEffect(() => {
        setCollapsed(!isOpenned)
    }, [isOpenned])

    return (
        <div className={`paragraph paragraph-collapse border-b border-primary-light${collapsed ? ` collapsed` : ` opened`}`} ref={ref}>
            <div className="collapse-title flex items-center py-4 cursor-pointer" onClick={() => toggle(index)}>
                <div className="h3 flex-1 text-primary uppercase">
                    {node.title}
                </div>
                <div className="icon flex-initial">
                    <i className={`p-2 border fas fa-${collapsed ? `plus border-primary-light bg-transparent text-primary-light` : `minus border-primary bg-primary text-white`}`}>
                        <span className="hidden">Déplier</span>
                    </i>
                </div>
            </div>
            <div className={`collapse-content py-4${collapsed ? ` hidden` : ` block`}`}>
                <div className="content">
                    <HtmlParser html={node.text.value}/>
                </div>
            </div>
        </div>
    )
})

CollapseParagraph.propTypes = {
    node: PropTypes.node.isRequired,
    index: PropTypes.number,
    isOpenned: PropTypes.bool,
    clickHandler: PropTypes.func
}

CollapseParagraph.defaultProps = {
    node: null,
    index: null,
    isOpenned: true,
    clickHandler: null
}

export const fragment = graphql`
  fragment CollapseParagraph on paragraph__collapse {
    id
    title: field_title
    text: field_text {
      value
    }
  }
`;
