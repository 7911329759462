import React from "react";
import {graphql} from "gatsby";
import {getParagraph} from "../helpers/paragraph-helpers";
import Layout from "../components/Layout";
import Metas from "../components/Metas";
import Title from "../components/Title";
import Form from "../components/Form";
import Breadcrumb from "../components/Breadcrumb";
import MenuLink from "../components/MenuLink";
import HtmlParser from "../components/HtmlParser";

export const excludedFromTypesense = [7];

const Page = ({data}) => {
    const isTsExcluded = excludedFromTypesense.includes(data.node.drupal_internal__nid);

    const paragraphs = data.node.relationships.paragraphs.map((paragraph) => {
        return getParagraph(paragraph, isTsExcluded);
    });

    const bodyProps = {
        className: "block content mb-8"
    }

    if (!isTsExcluded) {
        bodyProps["data-typesense-field"] = "body";
    }

    return (
        <Layout contentType={isTsExcluded ? null : data.node.internal.type}>
            <Metas title={data.node.title}/>
            <section className="section page-content">
                <div className="container mx-auto px-4 lg:max-w-screen-lg">
                    <Breadcrumb/>

                    <Title title={data.node.title} className="text-primary" isTsExcluded={isTsExcluded} />

                    {data.node.body && (
                        <div {...bodyProps}>
                            <HtmlParser html={data.node.body.value}/>
                        </div>
                    )}

                    <div className="paragraphs-list">
                        {paragraphs}
                    </div>

                    {data.node.relationships.webform && (
                        <section className="page-webform">
                            <Form webform={data.node.relationships.webform}/>
                        </section>
                    )}

                    {data.children && data.children.nodes.length > 0 && (
                        <section className="page-menu-children">
                            <div className="columns grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-8">
                                {data.children.nodes.map((item, index) => (
                                    <div key={index} className="column portal-link h-full">
                                        <MenuLink internalId={item.link.uri} className="block relative px-4 pt-4 pb-12 bg-primary-light text-white md:h-32 hover:bg-primary">
                                            <span className="font-semibold">{item.title}</span>
                                            <i className="fas fa-plus absolute bottom-0 right-0 m-4 p-2 ring-1 ring-white">
                                                <span className="hidden">En lire plus</span>
                                            </i>
                                        </MenuLink>
                                    </div>
                                ))}
                            </div>
                        </section>
                    )}
                </div>
            </section>
        </Layout>
    )
}

export default Page

export const query = graphql`
    query ($internalId: Int!, $menuItemId: String) {
        node: nodePage(
            status: {eq: true},
            drupal_internal__nid: {eq: $internalId}
        ) {
            drupal_internal__nid
            title
            body {
                summary
                value
            }
            changed
            relationships {
              paragraphs: field_paragraphes {
                type: __typename
                ... on Node {
                  ...TextParagraph
                  ...BannerParagraph
                  ...HighlightedParagraph
                  ...ListParagraph
                  ...FilesParagraph
                  ...FilesMultiuploadParagraph
                  ...AccordionParagraph
                  ...CollapseParagraph
                  ...ImageParagraph
                  ...ImageGalleryParagraph
                }
              }
              webform: field_webform {
                title
                drupal_internal__id
                elements {
                  name
                  type
                  attributes {
                    name
                    value
                  }
                }
              }
            }
            internal {
                type
            }
        }
        children: allMenuLinkContentMenuLinkContent(
            filter: {
                enabled: {eq: true},
                menu_name: {eq: "main"},
                drupal_parent_menu_item: {
                    eq: $menuItemId,
                    ne: null
                }
            }
            sort: {fields: weight, order: ASC}
        ) {
            nodes {
                title
                drupal_parent_menu_item
                drupal_id
                link {
                    uri
                    options {
                        attributes {
                            data_has_icon
                            data_icon
                            data_text_invisible
                            target
                        }
                    }
                }
            }
        }
    }
`
