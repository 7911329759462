import React from "react";
import Img from "gatsby-image";
import {graphql} from "gatsby";

export const CardParagraph = ({node}) => {
    return (
        <div className="paragraph paragraph-card card relative flex flex-col h-full">
            {node.relationships.image && (
                <div className="card-image relative flex-initial pl-4">
                    <Img
                        fluid={node.relationships.image.localFile.childImageSharp.fluid}
                        alt={node.title}
                    />
                </div>
            )}
            <div className={`card-content flex-1 p-4 bg-primary-light${node.relationships.image ? ` pt-32 -mt-28` : ``}`}>
                <div className="h-full flex flex-col">
                    <div className="title h3 mb-4 uppercase text-white">{node.title}</div>

                    <div
                        className={`content flex-1 text-white text-sm${node.link ? ` mb-4` : ``}`}
                        dangerouslySetInnerHTML={{__html: node.text.value}}
                    />

                    {node.link && (
                        <div className="link">
                            <a href={node.link.uri} target="_blank" className="button button-tertiary">En savoir plus</a>
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}

export const fragment = graphql`
  fragment CardParagraph on paragraph__card {
    id
    image: field_image {
      alt
    }
    title: field_title
    text: field_text {
      value
    }
    link: field_link {
      title
      uri
    }
    relationships {
      image: field_image {
        localFile {
          childImageSharp {
            fluid(maxWidth: 464, maxHeight: 294, cropFocus: CENTER, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`;
