import React from "react";
import { graphql } from "gatsby";
import Img from "gatsby-image";

export const BannerParagraph = ({ node }) => (
  <div className="paragraph paragraph-banner">
    <Img fluid={node.relationships.banner.localFile.childImageSharp.fluid} alt={node.banner.alt} />
  </div>
)

export const fragment = graphql`
  fragment BannerParagraph on paragraph__banner {
    id
    banner: field_image {
      alt
    }
    relationships {
      banner: field_image {
        localFile {
          childImageSharp {
            fluid(maxWidth: 1504, maxHeight: 382, cropFocus: CENTER, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`;
