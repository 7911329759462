import React from "react";
import { graphql } from "gatsby";
import { getParagraph } from "../../helpers/paragraph-helpers";

export const FilesParagraph = ({ node }) => {
    return (
        <div className={`paragraph paragraph-files`}>
            <div className="content">
                {node.title && (<h2 className={"title h2"}>{node.title}</h2>)}
                <ul className="files grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                    {node.relationships.files.map((file, index) => (
                        <div key={index} className="file">
                            {getParagraph(file)}
                        </div>
                    ))}
                </ul>
            </div>
        </div>
    )
}

export const fragment = graphql`
    fragment FilesParagraph on paragraph__files {
        id
        title: field_title
        relationships {
            files: field_paragraphs {
                type: __typename
                ... on Node {
                  ...FileParagraph
                }
            }
        }
    }
`;